import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ConnectorService} from '../../services/connector.service';
import {AlertService} from '../../services/alert.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../services/storage.service';
import {OperationResult} from '../../models/operation-result';
import {AuthenticationReturn} from '../../models/authentication-return';
import {UserCredentials} from '../../models/user-credentials';
import {CustomerNotActiveError} from '../../errors/customer-not-active.error';
import {NoCustomerError} from '../../errors/no-customer.error';
import {NotAuthenticatedError} from '../../errors/not-authenticated.error';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reseller-login',
  templateUrl: './reseller-login.component.html',
  styleUrls: ['./reseller-login.component.scss']
})
export class ResellerLoginComponent implements OnInit {

  loading = false;
  emailAddress: string;
  password: string;

  loginForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
    password: new FormControl('', Validators.required)
  });

  constructor(private router: Router,
              private storageService: StorageService,
              private connector: ConnectorService,
              private viewRef: ViewContainerRef,
              private alertService: AlertService,
              private route: ActivatedRoute,
              private toastr: ToastrService) {
                this.route.queryParams.subscribe(() => {
                  if (this.router.getCurrentNavigation().extras.state) {
                    this.emailAddress = this.router.getCurrentNavigation().extras.state.emailAddress;
                    this.password = this.router.getCurrentNavigation().extras.state.password;
                  }
                  console.log(this.emailAddress, this.password);
                  this.loginForm.controls.email.setValue(this.emailAddress);
                  this.loginForm.controls.password.setValue(this.password);
                });
              }

  async ngOnInit() {

    if (await this.storageService.hasCredentials()) {

      const credentials = this.storageService.getCredentials();

      this.loginForm.controls.email.setValue(credentials.email);
      this.loginForm.controls.password.setValue(credentials.password);

      await this.submitLoginForm();
    }
  }

  async submitLoginForm() {

    if (this.loginForm.valid) {
      await this.loginCustomer(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value);
    }
  }

  private async loginCustomer(email: string, password: string) {

    this.loading = true;

    await this.connector.authenticateCustomer(email, password)
      .then(async (data: OperationResult<AuthenticationReturn>) => {

        this.loading = false;

        this.storageService.setCredentials(
          new UserCredentials(email, data.Result.Code,password, data.Result.Token)
        );

        this.storageService.setCurrentUser(data.Result.Registration);

        await this.router.navigateByUrl('/reseller-dashboard');

      }).catch(async (error) => {

        this.loading = false;

        if (error instanceof CustomerNotActiveError
          || error instanceof NoCustomerError
          || error instanceof NotAuthenticatedError) {
          this.storageService.clearCredentials();
          this.storageService.clearCurrentUser();
          return await this.alertService.error(this.viewRef, 'Credenziali errate');
        }
        await this.alertService.error(this.viewRef, 'Errore durante il caricamento');
      }
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { StorageService } from "../../services/storage.service";
import { ConnectorService } from "../../services/connector.service";
import { Router } from "@angular/router";
import { UserCredentials } from "../../models/user-credentials";
import { OperationResult } from "../../models/operation-result";
import { VistaCouponUtilizzi } from "../../models/vista-coupon-utilizzi";
import { formatDate } from "@angular/common";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { VistaCouponInvoices } from "../../models/vista-coupon-invoices";
import { JoyUsage } from 'src/app/models/joy-usage';

@Component({
  selector: "app-reseller-bonus-details",
  templateUrl: "./reseller-bonus-details.component.html",
  styleUrls: ["./reseller-bonus-details.component.scss"]
})
export class ResellerBonusDetailsComponent implements OnInit {
  loading = false;

  mode = "coupons";

  couponsTable = {
    columns: [
      { prop: "dateAcq", name: "Data registrazione", sortable: false },
      { prop: "cf", name: "Codice Fiscale" }
    ],
    rows: [],
    columnMode: "force",
    messages: {
      emptyMessage: "Nessun Joy disponibile",
      totalMessage: "Totale"
    }
  };

  invoicesTable = {
    columns: [
      { prop: "number", name: "Fattura numero" },
      { prop: "date", name: "Data Fattura", sortable: false },
      { prop: "qty", name: "Quantità Joy<br/>OMAGGIO (cod. 10850)" }
    ],
    rows: [],
    columnMode: "force",
    messages: {
      emptyMessage: "Nessuna fattura disponibile",
      totalMessage: "Totale"
    }
  };

  constructor(
    private router: Router,
    private connector: ConnectorService,
    private storageService: StorageService
  ) {}

  async ngOnInit() {
    if (!(await this.storageService.hasCredentials())) {
      return await this.router.navigateByUrl("/reseller-access", {
        replaceUrl: true
      });
    }

    await this.loadData();
  }

  private async loadData() {
    this.loading = true;

    const credentials = await this.storageService.getCredentials();

    await this.getCoupons(credentials);

    await this.getInvoices(credentials);

    this.loading = false;
  }

  private async getCoupons(
    credentials: UserCredentials,
    retry: boolean = true
  ) {
    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .getJoyUsage()
          .then((data: OperationResult<JoyUsage[]>) => {
            const rows = [];

            data.Result.sort(function(a, b) {
              var c = new Date(a.UsageDate);
              var d = new Date(b.UsageDate);
              return d.getTime() - c.getTime();
            });

            for (const item of data.Result) {
              rows.push({
                cf: item.PersonalCode,                
                dateAcq: formatDate(item.UsageDate, "dd/MM/yyyy", "it-IT")                
              });
            }
            this.couponsTable.rows = rows;
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getCoupons(credentials, false);
            }
          });
      })
      .catch(async error => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.getCoupons(credentials, false);
        }
      });
  }

  private async getInvoices(
    credentials: UserCredentials,
    retry: boolean = true
  ) {
    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .getCouponsInvoices()
          .then((data: OperationResult<VistaCouponInvoices[]>) => {
            const rows = [];

            data.Result.sort(function(a, b) {
              var c = new Date(a.InvoiceDate);
              var d = new Date(b.InvoiceDate);
              return d.getTime() - c.getTime();
            });

            for (const item of data.Result) {
              rows.push({
                number: item.InvoiceNumber,
                date: formatDate(item.InvoiceDate, "dd/MM/yyyy", "it-IT"),
                qty: item.Quantity
              });
            }

            this.invoicesTable.rows = rows;
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getInvoices(credentials, false);
            }
          });
      })
      .catch(async error => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.getInvoices(credentials, false);
        }
      });
  }

  showCoupons() {
    this.mode = "coupons";
  }

  showInvoices() {
    this.mode = "invoices";
  }
}

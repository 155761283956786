import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: "app-campaign-info",
  templateUrl: "./campaign-info.component.html",
  styleUrls: ["./campaign-info.component.scss"]
})
export class CampaignInfoComponent implements OnInit {
  urlLocation = location.origin;
  constructor(private router: Router) {}

  ngOnInit() {
    console.log(location);
  }

  public goToPrimoJoy(){
    this.router.navigate(['ilprimojoy']);
  }

  public goToDiscover(){
    this.router.navigate(['discover']);
  }

  public goToRegulation(){
    this.router.navigate(['regulation']);
  }

  public goToShops(){
    this.router.navigate(['shops']);
  }

}

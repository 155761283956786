export class CouponRegistration {

    constructor(public RegistrationID: string,
                public Name: string,
                public PersonalCode: string,
                public Email: string,
                public Phone: string,
                public RegistrationDate: Date,
                public Password: string,
                public SaltKey: number,
                public AddressID: number,
                public Geolocalize: boolean,
                public Active: boolean,
                public Title: string,
                public Job: string,            
                public IsAdmin: boolean) { }

}

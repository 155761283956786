import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationFailedError, NotAuthenticatedError } from 'src/app/errors';
import { Coordinates, OperationResult, VistaCouponClientiGeo } from 'src/app/models';
import { AlertService } from 'src/app/services/alert.service';
import { ConnectorService } from 'src/app/services/connector.service';
import { GeolocationService } from 'src/app/services/geolocation.service';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss']
})
export class ShopsComponent implements OnInit {
  loading = false;
  distances: number[] = [10, 20, 50, 100];
  maxRange = 10;

  currentPosition: Coordinates;

  customers: VistaCouponClientiGeo[] = [];

  constructor(
    private router: Router,
    private connector: ConnectorService,
    private geolocationService: GeolocationService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService
  ) { }

  async ngOnInit() {
    this.loading = true;

    await this.getCustomers();

    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(position => {
        this.currentPosition = new Coordinates(
          position.coords.latitude,
          position.coords.longitude
        );

        this.sortCustomersByDistance();
      });
    }
  }

  async handleAddressChange(address: any) {
    this.currentPosition = new Coordinates(
      address.geometry.location.lat(),
      address.geometry.location.lng()
    );
    this.loading = true;
    await this.getCustomers();
    this.sortCustomersByDistance();
  }

  private async getCustomers(retry: boolean = true) {
    await this.connector
      .authenticate()
      .then(async () => {
        await this.connector
          .getCustomers()
          .then(async (data: OperationResult<VistaCouponClientiGeo[]>) => {
            this.loading = false;

            this.customers = data.Result;
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getCustomers(false);
            }

            this.loading = false;

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async error => {
        if (error instanceof AuthenticationFailedError && retry) {
          return await this.getCustomers(false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }

  async goToDetail(customer: VistaCouponClientiGeo) {
    await this.router.navigateByUrl("/reseller-detail", {
      state: {
        customer: JSON.stringify(customer)
      }
    });
  }

  private sortCustomersByDistance() {
    this.customers.forEach(customer => {
      customer.distanceKms = this.getCustomerDistanceKilometers(customer);
    });
    this.customers = this.customers.sort((a, b) => {
      return a.distanceKms === null
        ? 1
        : b.distanceKms === null
          ? -1
          : a.distanceKms > b.distanceKms
            ? 1
            : -1;
    });
  }

  private getCustomerDistanceKilometers(customer: VistaCouponClientiGeo) {
    if (
      !this.currentPosition ||
      !customer.Latitude ||
      customer.Latitude === "" ||
      !customer.Longitude ||
      customer.Longitude === ""
    ) {
      return null;
    }

    const distance = this.geolocationService.distance(
      this.currentPosition.latitude,
      this.currentPosition.longitude,
      +customer.Latitude,
      +customer.Longitude
    );
    return Math.floor(distance / 1000);
  }
}

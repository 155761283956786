import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ConnectorService } from 'src/app/services/connector.service';
import { CustomerReturn, OperationResult, UserCredentials } from 'src/app/models';
import { StorageService } from 'src/app/services/storage.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationFailedError, NoCustomerError, NotAuthenticatedError } from 'src/app/errors';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() public showPromos = true;
 
  public loading = false;
  public logged = false;
  
  constructor(private location: Location,
    private router: Router,
    private storageService: StorageService,
    private alertService: AlertService,
    private viewRef: ViewContainerRef,
    private connector: ConnectorService,
    private navigation: NavigationService) { }

  ngOnInit() {
    this.logged = this.showPromos && this.storageService.hasCredentials();
  }

  public goBack() {
    this.location.back();
  }

  public async goToPromoList(retry: boolean = true) {
    this.loading = true;
    await this.navigation.goToPromoList(this.viewRef);
    this.loading = false;
  }

}

import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ConnectorService } from "../../services/connector.service";
import { StorageService } from "../../services/storage.service";
import { Router } from "@angular/router";
import { AlertService } from "../../services/alert.service";
import { OperationResult } from "../../models/operation-result";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { AlreadyActiveError } from "../../errors/already-active.error";
import { NoCouponError } from "../../errors/no-coupon.error";
import { PersonalCodeInvalidError } from "../../errors/personal-code-invalid.error";
import { AuthenticationFailedError, CouponAlreadyUsedError } from "src/app/errors";
import { UserCredentials } from "src/app/models";
import { States } from "src/app/enums/states";
import { now } from 'moment';
import * as moment from 'moment';

@Component({
  selector: "app-reseller-request-consumer-code",
  templateUrl: "./reseller-request-consumer-code.component.html",
  styleUrls: ["./reseller-request-consumer-code.component.scss"],
})
export class ResellerRequestConsumerCodeComponent implements OnInit {
  loading = false;
  couponCode: string;

  currentUser: UserCredentials;
  states = States;
  choosenState: string = States.ITALY;

  codeRequestForm = new FormGroup({
    personalCode: new FormControl(
      "",
      Validators.compose([

        Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
        this.cfMonthValidator,
        Validators.required,
      ])
    ),
    acceptance: new FormControl(false, Validators.requiredTrue),
  });

  constructor(
    private router: Router,
    private connector: ConnectorService,
    private storageService: StorageService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService
  ) { }

  async ngOnInit() {
    if (!(await this.storageService.hasCredentials())) {
      return await this.router.navigateByUrl("/reseller-access", {
        replaceUrl: true,
      });
    }

    this.currentUser = this.storageService.getCredentials();
    this.codeRequestForm.controls.personalCode.valueChanges.subscribe(
      (value: string) => {
        if (value && value.toUpperCase() === "DEMO") {
          console.log(value);
          this.codeRequestForm.controls.personalCode.setErrors(null);
          this.codeRequestForm.controls.personalCode.clearValidators();
        } else {
          this.codeRequestForm.controls.personalCode.setValidators(
            Validators.compose([

              Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
              this.cfMonthValidator,
              Validators.required,
            ])
          );
        }
      }
    );

    // this.codeRequestForm.controls.sendEmail.valueChanges.subscribe(
    //   (value: boolean) => {
    //     if (value) {
    //       this.codeRequestForm.controls.emailAddress.setValidators([
    //         Validators.email,
    //         Validators.required
    //       ]);
    //     } else {
    //       this.codeRequestForm.controls.emailAddress.clearValidators();

    //       if (!this.codeRequestForm.controls.sendSms.value) {
    //         this.codeRequestForm.controls.sendSms.setValue(true);
    //       }
    //     }

    //     this.codeRequestForm.controls.emailAddress.updateValueAndValidity();
    //   }
    // );

    // this.codeRequestForm.controls.sendSms.valueChanges.subscribe(
    //   (value: boolean) => {
    //     if (value) {
    //       this.codeRequestForm.controls.phoneNumber.setValidators([
    //         Validators.required
    //       ]);
    //     } else {
    //       this.codeRequestForm.controls.phoneNumber.clearValidators();

    //       if (!this.codeRequestForm.controls.sendEmail.value) {
    //         this.codeRequestForm.controls.sendEmail.setValue(true);
    //       }
    //     }

    //     this.codeRequestForm.controls.phoneNumber.updateValueAndValidity();
    //   }
    // );
    (window as any).dataLayer.push({ event: "code.success.bonus35.reseller" });
  }

  async submitCodeRequestForm() {
    if (this.codeRequestForm.valid) {
      this.loading = true;

      await this.registerJoy(
        this.codeRequestForm.controls.personalCode.value
      );
    }
  }

  public canRequest():boolean{
    if(this.codeRequestForm.valid) {
      return true;
    }
    else{
      if(!this.codeRequestForm.controls.acceptance.errors){
        if(this.codeRequestForm.controls.personalCode.errors){
          if(Object.keys(this.codeRequestForm.controls.personalCode.errors).length == 1 && 
            this.codeRequestForm.controls.personalCode.errors.toOldButOk){
              return true;
          }
        }
        else{
          return true;
        }
      }
    }


    return false;
  }

  private async registerJoy(
    code: string,
    retry: boolean = true
  ) {
    if (code.toUpperCase() === "DEMO") {
      await this.router.navigateByUrl("/reseller-consumer-code-success", {
        state: {
          couponCode: "000000",
          personalCode: this.codeRequestForm.controls.personalCode.value.toUpperCase(),
        },
      });
      return;
    }
    const credentials: UserCredentials = this.storageService.getCredentials();
    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .registerJoy(code)
          .then(async (result: OperationResult<any>) => {
            this.loading = false;

            this.couponCode = result.Result;
            await this.router.navigateByUrl("/reseller-consumer-code-success", {
              state: {
                couponCode: result.Result,
                personalCode: this.codeRequestForm.controls.personalCode.value,
              },
            });

          })
          .catch(async (error) => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.registerJoy(code, false);
            }

            this.loading = false;

            if (error instanceof AlreadyActiveError) {
              return await this.alertService.error(
                this.viewRef,
                "Il Codice Fiscale inserito è già stato utilizzato"
              );
            }

            if (error instanceof NoCouponError) {
              return await this.alertService.error(
                this.viewRef,
                "Promozione non valida"
              );
            }

            if (error instanceof PersonalCodeInvalidError) {
              return await this.alertService.error(
                this.viewRef,
                "Il Codice Fiscale inserito non è valido"
              );
            }

            if (error instanceof CouponAlreadyUsedError) {
              return await this.alertService.error(
                this.viewRef,
                "Codice fiscale già registrato"
              );
            }
          
            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async (error) => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.registerJoy(code, false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }

  private async sendCode(
    code: string,
    email: string,
    phone: string,
    retry: boolean = true
  ) {
    const credentials: UserCredentials = this.storageService.getCredentials();
    await this.connector
    .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .sendCode(code, email, phone)
          .then(async () => {
            this.loading = false;

            await this.alertService.success(
              this.viewRef,
              "Codice inviato correttamente"
            );
          })
          .catch(async (error) => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.sendCode(code, email, phone, false);
            }

            this.loading = false;

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async (error) => {
        if (error instanceof AuthenticationFailedError && retry) {
          return await this.sendCode(code, email, phone, false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }


  cfMonthValidator(control: AbstractControl) {
    let cf: string = control.value;
    if (cf.length < 11) {
      return null;
    }

    const tabellamesi = {
      "A": 1,
      "B": 2,
      "C": 3,
      "D": 4,
      "E": 5,
      "H": 6,
      "L": 7,
      "M": 8,
      "P": 9,
      "R": 10,
      "S": 11,
      "T": 12
    };

    let aa = cf.substr(6, 2);
    var mm = cf.substr(8, 1);
    var gg = cf.substr(9, 2);

    let anno = parseInt(aa, 10);
    anno = 2000 + anno;
    let currentDate = new Date();
    let annoCorrente = currentDate.getUTCFullYear()

    if (anno > annoCorrente) {
      anno = anno - 100;
    }
    let giorno = parseInt(gg, 10);
    if (giorno > 31) giorno -= 40;

    let mese = mm.toUpperCase();

    let birthDate = new Date(anno, tabellamesi[mese] - 1, giorno);

    if(moment(birthDate) > moment().startOf('day').add(1, 'day')){
      return {
        toEarly: true
      }
    }

    let minDate = moment(currentDate).startOf('day').subtract(4, 'months');
    if (moment(birthDate) <= minDate) {
      return {
        toOld: true
      }
    }

    /*
    minDate = moment(currentDate).startOf('day').subtract(3, 'months');
    if (moment(birthDate) <= minDate) {
      return {
        toOldButOk: true
      }
    }
    */
   
   
    return null
  };


  // FRNBGR90M29Z130S San Marino
  // BNGFNC90M29Z106B Vatican

  chooseState(state: string) {
    this.choosenState = state;
    if (state === States.ITALY) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([

          Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          this.cfMonthValidator,
          Validators.required,
        ])
      );
    } else if (state === States.SANMARINO) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([

          Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          this.cfMonthValidator,
          Validators.required,
        ])
      );
    } else if (state === States.VATICAN) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([
          Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          this.cfMonthValidator,
          Validators.required,
        ])
      );
    }
  }




}

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {StorageService} from '../../services/storage.service';
import {ConnectorService} from '../../services/connector.service';
import {AlertService} from '../../services/alert.service';
import {OperationResult} from '../../models/operation-result';
import {AuthenticationReturn} from '../../models/authentication-return';
import {NoCustomerError} from '../../errors/no-customer.error';
import {CustomerNotActiveError} from '../../errors/customer-not-active.error';
import {NotAuthenticatedError} from '../../errors/not-authenticated.error';
import { UserCredentials } from 'src/app/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading = false;

  constructor(private router: Router,
    private storageService: StorageService,
    private connector: ConnectorService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService,
    private route: ActivatedRoute) { }

    private token: string;
  ngOnInit() {

    this.route.queryParams.subscribe({
      next: (params: Params) => {
        if (params.token) {
          this.token = params.token;
          this.loginByPromise(this.connector.reAuthenticate(this.token))
        }

      }
    });

  }

  async loginByPromise(promise: Promise<OperationResult<AuthenticationReturn>>) {
    this.loading = true;
    await promise.then(async (data: OperationResult<AuthenticationReturn>) => {

      this.loading = false;

      this.storageService.setCredentials(
        new UserCredentials(data.Result.Registration.Email, data.Result.Code, "",this.token)
      );

      this.storageService.setCurrentUser(data.Result.Registration);

      await this.router.navigateByUrl('/reseller-dashboard');

    }).catch(async (error) => {

      this.loading = false;

      if (error instanceof NoCustomerError
        || error instanceof CustomerNotActiveError
        || error instanceof NotAuthenticatedError) {

        this.storageService.clearCredentials();
        this.storageService.clearCurrentUser();

        await this.alertService.error(this.viewRef, 'Credenziali errate.\nEffettua nuovamente il login');

        return await this.router.navigateByUrl('/reseller-access');

      }

      await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

    });
  }

  async loginCustomer() {

    if (!await this.storageService.hasCredentials()) {
      return await this.router.navigateByUrl('/reseller-access');
    }

    const credentials = this.storageService.getCredentials();

    this.loading = true;

    this.loginByPromise(this.connector.authenticateCustomer(credentials.email, credentials.password))


  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ilprimojoy',
  templateUrl: './ilprimojoy.component.html',
  styleUrls: ['./ilprimojoy.component.scss']
})
export class IlprimojoyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

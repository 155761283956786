import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PROMOTION_ID } from 'src/app/app.settings';
import { CouponRegistration, CustomerReturn, UserCredentials } from 'src/app/models';
import { PromoReturn } from 'src/app/models/promo-return';
import { AlertService } from 'src/app/services/alert.service';
import { ConnectorService } from 'src/app/services/connector.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-promo-list',
  templateUrl: './promo-list.component.html',
  styleUrls: ['./promo-list.component.scss']
})
export class PromoListComponent implements OnInit {
  loading: boolean;
  promos: PromoReturn[];
  user: UserCredentials;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private viewRef: ViewContainerRef,
    private alertService: AlertService,
    private storageService:StorageService,
    private connector: ConnectorService,
    ) {
    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.promos = JSON.parse(
          this.router.getCurrentNavigation().extras.state.promos
        );
      }
    });
  }

  async ngOnInit() {
    this.user = this.storageService.getCredentials();
    if (!this.promos) {
      await this.router.navigateByUrl("/reseller-access", { replaceUrl: true });
    }
    else {
      if (this.promos) {
        this.promos.forEach(p => {
          this.connector.getImage(p.Id).then((data: Blob) => {
            var reader = new FileReader();

            // The magic always begins after the Blob is successfully loaded
            reader.onload = function () {
              p.imgUrl = reader.result as string;
            };
            // Since everything is set up, let’s read the Blob and store the result as Data URI
            reader.readAsDataURL(data);
          }).catch(err => { console.log(err) });
        }
        );
      }
    }
  }

  public availablePromos(): PromoReturn[] {
    return this.promos.filter(p => p.Id != PROMOTION_ID);
  }

  public openPromo(promo: PromoReturn) {
    if (promo.Link1) {
      window.open(promo.Link1 + "?token=" + this.user.token, "_blank");
    }
    else {
      this.alertService.error(this.viewRef, "Link alla promo non disponibile")
    }
  }

}

import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConnectorService } from '../../services/connector.service';
import { AlertService } from '../../services/alert.service';
import { AuthenticationFailedError } from '../../errors/authentication-failed.error';
import { NoCustomerError } from '../../errors/no-customer.error';
import { NotAuthenticatedError } from '../../errors/not-authenticated.error';
import { OperationResult } from '../../models/operation-result';
import { CustomerReturn } from '../../models/customer-return';
import { StorageService } from 'src/app/services/storage.service';
import { UserCredentials } from 'src/app/models';
import { PROMOTION_ID } from 'src/app/app.settings';

@Component({
  selector: 'app-reseller-access',
  templateUrl: './reseller-access.component.html',
  styleUrls: ['./reseller-access.component.scss']
})
export class ResellerAccessComponent implements OnInit {
  loading = false;

  verifyCodeForm = new FormGroup({
    code: new FormControl('', Validators.required)
  });

  constructor(
    private router: Router,
    private connector: ConnectorService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService,
    private storageService: StorageService
  ) { }

  ngOnInit() { }

  async submitVerifyForm() {
    if (this.verifyCodeForm.valid) {
      this.loading = true;
      await this.getCustomer(this.verifyCodeForm.controls.code.value);
    }
  }

  private IsAlreadyRegistered(customer: CustomerReturn) {
    if (customer.PromoActive) {
      for (var p of customer.PromoActive) {
        if (p.Id == PROMOTION_ID) {
          return true;
        }
      }
    }
    return false;
  }

  private async getCustomer(code: string, retry: boolean = true) {
    const credidentials: UserCredentials = this.storageService.getCredentials();
    await this.connector
      .authenticate()
      .then(async () => {
        await this.connector
          .getCustomer(code)
          .then(async (data: OperationResult<CustomerReturn>) => {
            this.loading = false;

            if (this.IsAlreadyRegistered(data.Result)) {
              return await this.alertService.error(
                this.viewRef,
                'Il codice cliente inserito risulta già registrato'
              );
            }

            await this.router.navigateByUrl('/reseller-create-first-user', {
              state: {
                customer: JSON.stringify(data.Result)
              }
            });
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getCustomer(code, false);
            }

            this.loading = false;

            if (error instanceof NoCustomerError) {
              return await this.alertService.error(
                this.viewRef,
                'Codice cliente non valido'
              );
            }

            await this.alertService.error(
              this.viewRef,
              'Errore durante il caricamento'
            );
          });
      })
      .catch(async error => {
        if (error instanceof AuthenticationFailedError && retry) {
          return await this.getCustomer(code, false);
        }
        this.loading = false;
        await this.alertService.error(
          this.viewRef,
          'Errore durante il caricamento'
        );
      });
  }
}

import { Component, ComponentRef } from "@angular/core";
import { IModalDialog, IModalDialogOptions } from "ngx-modal-dialog";

@Component({
  selector: "app-reseller-campaign-terms-modal",
  templateUrl: "./reseller-campaign-terms-modal.component.html",
  styleUrls: ["./reseller-campaign-terms-modal.component.scss"]
})
export class ResellerCampaignTermsModalComponent implements IModalDialog {
  listItems: string[] = [
    "I succhietti Joy REGALO 0-6m di J BIMBI® sono disponibili solo nei Negozi Bimbi (Farmacie, Parafarmacie, Sanitarie, Negozi Prima Infanzia, ecc.) registrati alla campagna “Il primo Joy te lo regaliamo noi!” disponibile sul sito www.jbimbi.com.",
    "I succhietti Joy REGALO 0-6m di J BIMBI® vengono omaggiati solo ai Negozi Bimbi che si registrano, tramite l'agente Colpharma, alla campagna “Il primo Joy te lo regaliamo noi!” e che trattano i succhietti J BIMBI®. Eventuali ordini contenenti i succhietti Joy REGALO 0-6m di J BIMBI® di clienti non registrati non saranno accettati.",
    "Eventuali Negozi Bimbi registrati alla campagna “Il primo Joy te lo regaliamo noi!” non piu' trattanti i succhietti J BIMBI® o che utilizzassero la campagna per promuovere altri marchi concorrenti a J BIMBI®, verranno insindacabilmente disabilitati allo scarico / riassortimento dei succhietti Joy REGALO 0-6m di J BIMBI® e non saranno piu' visibili nello Store Locator relativo alla campagna.",
    "L'assegnazione inziale al Negozio Bimbi di succhietti Joy REGALO 0-6m di J BIMBI® varia in base al valore dell'ordine di impianto dei prodotti J BIMBI®. I succhietti Joy REGALO 0-6m di J BIMBI® sono confezionati in imballi indivisibili da 27 pz; la dotazione inziale e i successivi riassortimenti sono in multipli di 27 pz (27, 54, 81, ecc.).",
    "Il RIASSORTIMENTO di succhietti Joy REGALO 0-6m di J BIMBI® viene effettuato automaticamente sul primo ordine Colpharma disponibile, anche di prodotti non J BIMBI, con un valore minimo pari a 250,00 euro + IVA.",
    "Il RIASSORTIMENTO di succhietti Joy REGALO 0-6m di J BIMBI® viene calcolato automaticamente in base ai succhietti scaricati per ripristinare in eccesso l'assegnazione inziale fino ad un massimo pari a: assegnazione inziale + 26 pz.",
    "Le informazioni relative ai succhietti Joy REGALO 0-6m di J BIMBI® su ASSEGNAZIONE INIZIALE, RIASSORTIMENTI, OMAGGIATI o DISPONIBILI presso il Negozio Bimbi sono consuntivabili sulla APP «Il primo JOY» (scaricabile su Smartphone e Tablet) o sul sito www.jbimbi.com nella propria utenza/account.",
    "I succhietti Joy REGALO 0-6m di J BIMBI® vengono omaggiati solo nei Negozi Bimbi, e' VIETATA LA PROMOZIONE E L'OMAGGIO TRAMITE E-COMMERCE/INTERNET",
    "Non e' possibile sostituire il succhietto Joy REGALO 0-6m di J BIMBI®, appositamente confezionato e non destinato alla vendita, con altro prodotto o altro succhietto destinato alla vendita (anche se di J BIMBI®).",
    "Non e' possibile vendere il succhietto Joy REGALO 0-6m di J BIMBI® (vietata la vendita) come specificato sulla confezione",
    "Il succhietto Joy REGALO 0-6m di J BIMBI® puo' essere scaricato solo registrando, tramite tastiera o scanner, il codice fiscale del bimbo (anche con tessera sanitaria).",
    "Per ogni bimbo e' possibile scaricare 1 solo succhietto Joy REGALO 0-6m di J BIMBI®. Una volta registrato, il codice fiscale verra' disabilitato per lo scarico di un altro succhietto.",
    "Il succhietto Joy REGALO 0-6m di J BIMBI® puo' essere omaggiato solo a bimbi di eta' inferiore ai 3 mesi al momento della registrazione, come comprovato dal codice fiscale. Non e' possibile registrare il codice fiscale di bimbi di eta' superiore ai 3 mesi.",
    "Colpharma si riserva di controllare la veridicita' dei codici fiscali registrati e di denunciare alle autorita' competenti eventuali anomalie.",
    "Non e' possibile registrare bimbi a cui omaggiare il succhietto se la giacenza di Joy REGALO 0-6m di J BIMBI® e' uguale a 0 pz."
    
  ];

  constructor() {}

  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<any>>
  ) {}
}

import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { CouponRegistration } from "../../models/coupon-registration";
import { CustomerReturn } from "../../models/customer-return";
import { CouponTotal } from "../../models/coupon-total";
import { Router } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { ConnectorService } from "../../services/connector.service";
import { AlertService } from "../../services/alert.service";
import { OperationResult } from "../../models/operation-result";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { AuthenticationFailedError } from "../../errors/authentication-failed.error";
import { UserCredentials } from "src/app/models";
import * as moment from "moment";
import { JoyTotal } from 'src/app/models/joy-total';
import { NavigationService } from 'src/app/services/navigation.service';
import { Location } from '@angular/common';

@Component({
  selector: "app-reseller-dashboard",
  templateUrl: "./reseller-dashboard.component.html",
  styleUrls: ["./reseller-dashboard.component.scss"]
})
export class ResellerDashboardComponent implements OnInit {
  loading = false;

  customerCode: string;
  user: CouponRegistration;

  customer: CustomerReturn;
  joyTotal: JoyTotal;

  lastUpdated = moment()
    .subtract(1, "days")
    .format("DD-MM-YYYY");

  constructor(
    private router: Router,
    private storageService: StorageService,
    private connector: ConnectorService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService,
    private navigation: NavigationService,
    private location: Location
  ) {}

  async ngOnInit() {
    const credidentials = await this.storageService.hasCredentials();
    if (!credidentials) {
      return await this.router.navigateByUrl("/reseller-access", {
        replaceUrl: true
      });
    }

    this.customerCode = this.storageService.getCredentials().code;

    this.user = this.storageService.getCurrentUser();

    if (!this.user) {
      return await this.router.navigateByUrl("/reseller-access", {
        replaceUrl: true
      });
    }

    await this.loadData();
  }
  private async loadData() {
    this.loading = true;

    await this.getCustomer();

    await this.getCouponsTotal();

    this.loading = false;
  }

  private async getCustomer(retry: boolean = true) {
    const credentials: UserCredentials = this.storageService.getCredentials();
    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .getCustomer(this.customerCode)
          .then(async (data: OperationResult<CustomerReturn>) => {
            this.customer = data.Result;
            this.storageService.saveCustomer(data.Result);
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getCustomer(false);
            }
          });
      })
      .catch(async error => {
        if (error instanceof AuthenticationFailedError && retry) {
          return await this.getCustomer(false);
        }
      });
  }

  private async getCouponsTotal(retry: boolean = true) {
    const credentials = this.storageService.getCredentials();

    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .getJoyTotal()
          .then(async (data: OperationResult<JoyTotal>) => {
            this.joyTotal = data.Result;
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getCouponsTotal(false);
            }
          });
      })
      .catch(async error => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.getCouponsTotal(false);
        }
      });
  }

  async manageUsers() {
    if (this.user && this.user.IsAdmin && this.customer) {
      await this.router.navigateByUrl("/reseller-manage-users", {
        state: {
          customer: JSON.stringify(this.customer)
        }
      });
    }
  }

  async changePassword() {
    await this.router.navigateByUrl("/reseller-change-password", {
      state: {
        customerCode: this.customerCode,
        emailAddress: this.user.Email
      }
    });
  }

  public async goToPromoList(retry: boolean = true) {
    this.loading = true;
    await this.navigation.goToPromoList(this.viewRef);
    this.loading = false;
  }

  public goBack() {
    this.location.back();
  }

  async logout() {
    await this.alertService.confirm(
      this.viewRef,
      "Logout",
      "Sei sicuro di voler uscire?",
      () => true,
      () => {
        this.storageService.clearCredentials();
        this.storageService.clearCurrentUser();
        this.router.navigateByUrl("/reseller-access", { replaceUrl: true });
        return true;
      }
    );
  }
}

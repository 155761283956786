import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { VideoTutorial } from "src/app/models/video-tutorial";
import { Location } from '@angular/common'

@Component({
  selector: "video-tutorial-list",
  templateUrl: "./video-tutorial-list.component.html",
  styleUrls: ["./video-tutorial-list.component.scss"]
})
export class VideoTutorialListComponent implements OnInit {
  public tutorials: VideoTutorial[] = [];
  constructor(private router:Router,private location:Location) {
    this.tutorials.push( { title: 'Fare il login', text: '', thumbnailUrl:'/assets/img/tutorial-login.png', videoUrl:'https://www.youtube.com/embed/itFe3JUFxxk?enable_js=1' })
    this.tutorials.push( { title: 'Accesso facilitato da PC', text: '', thumbnailUrl:'/assets/img/tutorial-pc.png', videoUrl:'https://www.youtube.com/embed/v51l9mtIYoI?enable_js=1' })
    this.tutorials.push( { title: 'Cambiare la password', text: '', thumbnailUrl:'/assets/img/tutorial-password.png', videoUrl:'https://www.youtube.com/embed/988LrOxo6og?enable_js=1' })
    this.tutorials.push( { title: 'Gestire i succhietti Joy REGALO', text: '', thumbnailUrl:'/assets/img/tutorial-bonus.png', videoUrl:'https://www.youtube.com/embed/0yCij33sxiM?enable_js=1' })
  }

  async ngOnInit() {
    
  }

  async goToTutorial(tutorial:VideoTutorial){
    await this.router.navigateByUrl('/video-tutorial', {
      state: {
          tutorial: JSON.stringify(tutorial)
      }
  });
  }

  public goBack() {
    this.location.back();
  }
}
